module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"BAUZ.STUDIO","short_name":"bauz.studio","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/assets/images/bauz.studio.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"b9c61f47114bb23ff101c964cec87264"},
    },{
      plugin: require('../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-prismic/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"bauz","accessToken":"MC5YcWtnbFJBQUFDRUFhZ1ky.SBgjPO-_vXjvv73vv73vv73vv707HjXvv70z77-9OH_vv70FEDnvv73vv73vv73vv73vv70KJO-_vSrvv70","schemas":{"project":{"Main":{"uid":{"type":"UID","config":{"label":"uid","placeholder":"This will be the url of the project"}},"sort_order":{"type":"Number","config":{"label":"Sort Order","placeholder":"1-100"}},"featured":{"type":"Boolean","config":{"default_value":true,"label":"Featured"}},"background_color":{"type":"Color","config":{"label":"Background Color"}},"dark_mode":{"type":"Boolean","config":{"placeholder_false":"Dark Logo","placeholder_true":"Light Logo","default_value":false,"label":"Dark Mode"}},"title":{"type":"StructuredText","config":{"single":"heading2","label":"Title","placeholder":"Project Title"}},"tags":{"type":"StructuredText","config":{"single":"heading3","label":"tags","placeholder":"Project Tags"}},"cover_image":{"type":"Image","config":{"constraint":{},"thumbnails":[{"name":"mobile","width":414,"height":736}],"label":"Cover Image"}}}},"featuredProjects":{"Main":{"uid":{"type":"UID","config":{"label":"uid","placeholder":"This will be the url of the project"}},"sort_order":{"type":"Number","config":{"label":"Sort Order","placeholder":"1-100"}},"featured":{"type":"Boolean","config":{"default_value":true,"label":"Featured"}},"background_color":{"type":"Color","config":{"label":"Background Color"}},"dark_mode":{"type":"Boolean","config":{"placeholder_false":"Dark Logo","placeholder_true":"Light Logo","default_value":false,"label":"Dark Mode"}},"title":{"type":"StructuredText","config":{"single":"heading2","label":"Title","placeholder":"Project Title"}},"tags":{"type":"StructuredText","config":{"single":"heading3","label":"tags","placeholder":"Project Tags"}},"cover_image":{"type":"Image","config":{"constraint":{},"thumbnails":[{"name":"mobile","width":414,"height":736}],"label":"Cover Image"}}}}}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
