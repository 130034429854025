import { css } from "@emotion/core"

export const mqHandheldLandscape = `@media screen and (min-width: 20em)
                                            and (max-width: 48em)
                                            and (orientation: landscape)`
export default {
  useLocalStorage: false,
  breakpoints: [`48em`, `56.25em`, `81.25em`],
  colors: {
    text: "var(--text)",
    background: "#fff",
    overlay: `rgb(255, 255, 255, 0.9)`,
    primary: "#07c",
    secondary: "#05a",
    accent: "#609",
    muted: "#f6f6f6",
  },
  fontSizes: [11, 12, 14, 15, 20, 22, 24, 28, 82],
  fonts: {
    body: `"SuisseRegular"`,
    bold: `"SuisseMedium"`,
    // italic: `"SuisseRegularItalic"`,
    secondaryItalic: `"FragenItalic"`,
  },
  lineHeights: [`16px`, `18px`, `28px`, `42px`, `58px`],
  fontWeights: {},
  sizes: [0, 31, 62, 68, 97, 314, 350, 530],
  space: [0, 4, 8, 16, 31, 62, 128, 256, 512],
  text: {
    heading: {
      fontSize: [2, 2, 2, 4],
      fontWeight: `lighter`,
      m: 0,
      transition: `color 0.5s ease`,
      "&:before": {
        content: `"\\2191 \\00a0"`,
      },
    },
    copy: {
      lineHeight: [0, 1, 1, 2],
      fontSize: [3, 3, 3, 4],
      mt: [3, 3, 3, 4],
      mb: 0,
      transition: `color 0.5s ease`,
    },
    link: {
      fontWeight: `lighter`,
      fontSize: [3, 3, 3, 4],
      mt: 3,
      color: `inherit`,
      transition: `color 0.5s ease`,
      textDecoration: `none`,
      "&:before": {
        content: `"\\2192 \\00a0"`,
      },
    },
    logo: {
      fill: `text`,
      transition: `fill 0.5s ease`,
    },
  },
  styles: {
    root: {
      fontFamily: `body`,
    },
    h2: {
      variant: `text.heading`,
    },
    h3: {
      variant: `text.heading`,
    },
    p: {
      variant: `text.copy`,
    },
    a: {
      variant: `text.link`,
      display: `block`,
    },
    ul: {
      variant: `text.copy`,
      listStyleType: `none`,
      p: 0,
    },
  },
}

// @font-face {
//   font-family: "SuisseRegularItalic";
//   src: url("${SuisseRegularItalic}") format("opentype");
// }
export const cssGlobal = css`
  @font-face {
    font-family: "SuisseMedium";
    src: url("/fonts/Suisse-BP-Intl-Medium.otf") format("opentype");
  }
  @font-face {
    font-family: "SuisseRegular";
    src: url("/fonts/Suisse-BP-Intl-Regular.otf") format("opentype");
  }
  @font-face {
    font-family: "FragenItalic";
    src: url("/fonts/Fragen-MediumItalic.otf") format("opentype");
  }
  :root {
    --text: black;
    --background: white;
    --color-mode: light;
  }
  html {
    scroll-behavior: smooth;
  }
`
